import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import logo from './images/logo_white.png';
import back from './images/goback.png';
import * as version from './Version';

class About extends React.Component {
 

   constructor(props) {
    super(props);
    this.state = {
		bgColor:'',
		textColor: '',
	 };
  }

 	componentDidMount() {
		this.setState({
	  		
		});
	}


 render() {

  this.goback = () => (event) => {
    if (document.fullscreenElement) {
        document.exitFullscreen();
    }
    window.history.back();
  }

 	const parentdiv = {
	  	minHeight: '100vh',
	}

   return (
      <div style={parentdiv}>
      		
      	{/*logo section*/}
        <span className="detailgoback_span"><img id="goback" onClick={this.goback()} className="detailgoback" src={back} /></span>
        <div className="col-md-12 col-sm-12 col-xs-12">
	        <div className="text_align_center">
	        	<img src={logo} alt="LOGO" className="assetdetail_logo_myaccount"/>
	          <div className="myaccount_text">V-NOVA</div>
          </div>
        </div>
      {/*End of logo section*/}

      	<div className="col-md-12 col-sm-12 col-xs-12 myaccount_container">
      		<div className="myaccount_details"><span className="myaccount_details_name">Player : </span>Shaka Player (v4.12.6)</div>
      		<div className="myaccount_details"><span className="myaccount_details_name">LCEVC decoder Version: </span>67ecedb (03-02-2025)</div>
      		<div className="myaccount_details"><span className="myaccount_details_name">Version : </span>{version.Version}</div>
      	</div>

      </div>   
      );
   }

}

export default About;